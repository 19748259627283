export function Status() {
  const servers = [
    { name: 'Mobile2 Farmbot', status: 'working', bot: 'PhantomForge' },
    { name: 'Mobile2 Fishbot', status: 'working', bot: 'Moonclaw' },
    { name: 'Misali2', status: 'working', bot: 'Moonclaw' },
  ];

  return (
    <div className="min-h-screen bg-gray-950 pt-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-white mb-8">Service Status</h1>
        <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl">
          <div className="grid grid-cols-3 bg-gray-800 p-4">
            <h3 className="text-gray-300 font-semibold">Server Name</h3>
            <h3 className="text-gray-300 font-semibold">Status</h3>
            <h3 className="text-gray-300 font-semibold">Bot</h3>
          </div>
          {servers.map((server, index) => (
            <div
              key={server.name}
              className={`grid grid-cols-3 p-4 ${
                index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-900/50'
              }`}
            >
              <span className="text-gray-300">{server.name}</span>
              <span
                className={`flex items-center ${
                  server.status === 'working'
                    ? 'text-green-500'
                    : server.status === 'updating'
                    ? 'text-yellow-500'
                    : 'text-red-500'
                }`}
              >
                <span
                  className={`w-2 h-2 rounded-full mr-2 ${
                    server.status === 'working'
                      ? 'bg-green-500'
                      : server.status === 'updating'
                      ? 'bg-yellow-500'
                      : 'bg-red-500'
                  }`}
                ></span>
                {server.status.charAt(0).toUpperCase() + server.status.slice(1)}
              </span>
              <span className="text-gray-300">{server.bot}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
